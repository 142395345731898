<template>
  <div>
    <v-card>
      <div class="tc-title">
      <v-row class="pt-3 pb-3 py-2 px-5" justify="space-between">
        <h3>TPO Registration Report</h3>
        <v-btn color="blue" dark @click="exceldialog = !exceldialog">Excel Import</v-btn>
      </v-row>
    </div>
      <v-dialog width="350" persistent v-model="exceldialog">
        <v-card>
          <v-card-title class="c-title">Excel import</v-card-title>
          <v-card-text><br />
            <v-row justify="end">
              <a :href="url">
                <v-icon large color="blue" class="icon-quick" title="Download">
                  mdi-file-excel
                </v-icon>
              </a>
            </v-row>
            <v-file-input v-model="excelfile" label="Import Excel"></v-file-input>
            <v-select multiple :items="placement_type_list" label="Offer type" prepend-icon="mdi-file" item-text="name"
              item-value="id" v-model="placement_type_id"></v-select>

            <v-select :items="aacademic_list" label="Academic Year" prepend-icon="mdi-file" item-text="ay"
              item-value="id" v-model="ayid"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-row class="pr-5" justify="end">
              <v-btn @click="clear()" text>cancel</v-btn>
              <v-btn @click="uploadexcel()" dark color="blue">Upload</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
       

          <v-row  justify="center">
           <div>
            <br/> <small style="color: red;">*</small><b>OFFER TYPE</b>
           </div>
           </v-row>
           <v-row  justify="center">
            <v-checkbox v-for="(role, index) in placement_type_list" :key="index"
              :label="placement_type_list[index].name" color="success"
              v-model="placement_type_list[index].value"></v-checkbox>

            <!-- <span v-if="addrole.length ==0">
            <v-chip>
              <v-icon left color="red">mdi-exclamation-thick</v-icon>Please select roles
            </v-chip>
          </span>-->
          </v-row>
      

        <v-row justify="center">
          <v-col cols="12" sm="2" md="2">
            <v-autocomplete outlined :items="ay_list" item-text="ay" item-value="id" v-model="ay">
              <template #label>
                Academic Year &nbsp;<small style="color: red">*</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">

            <v-autocomplete :items="institute_list" outlined item-text="name" item-value="id" v-model="institute"
              @change="getProgramType()">
              <template #label>
                Organization &nbsp;<small style="color: red">*</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">

            <v-autocomplete autocomplete="off" outlined :items="programtype_list" item-text="name" item-value="id"
              v-model="programtype" @change="getProgram()">
              <template #label>
                Program Type &nbsp;<small style="color: red">*</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">

            <v-autocomplete  outlined :items="program_list"  v-model="program" item-text="name" item-value="id" >
              <template #label>
                Program<small style="color: red">*</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">
                    
                    <v-autocomplete outlined autocomplete="off" :items="year_list" item-text="name" item-value="id"
                        v-model="year">
                        <template #label>
                          Year <small style="color:red;">*</small>
              </template>
                      </v-autocomplete>
                </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn class="mt-2" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-5">
          <v-btn v-if="studentdata.length != 0" @click="exportexcel()" color="success" dark class="mb-2">
            <v-icon dark>mdi-file-excel</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <center v-if="studentdata.length == 0 && loading">
      <!-- <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular> -->
      <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs"
        type="list-item-three-line"></v-skeleton-loader>
    </center>
    <v-card v-else-if="studentdata.length != 0" class="my-2">
      <v-simple-table>
        <thead>
          <th class="text-left" @click.prevent="sortBy('srno')">Sr No. <v-icon>mdi-swap-vertical</v-icon></th>
          <th class="text-left" @click.prevent="sortBy('organization')">Organization <v-icon>mdi-swap-vertical</v-icon></th>
          <th class="text-left" @click.prevent="sortBy('grno')">Grno <v-icon>mdi-swap-vertical</v-icon></th>
          <th class="text-left" @click.prevent="sortBy('name')">Name <v-icon>mdi-swap-vertical</v-icon></th>
          <th class="text-left" @click.prevent="sortBy('program')">Program <v-icon>mdi-swap-vertical</v-icon></th>
          <th class="text-left" v-for="(role, index) in placement_type_list1" :key="index">
            {{ placement_type_list1[index].name }}<v-icon>mdi-swap-vertical</v-icon>
          </th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in studentdata" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.org }}</td>
            <td>{{ item.grno }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.program }}</td>
            <td v-for="(item1, index) in item.place" :key="index">
              <div v-if="item1.isregister">
                <v-icon class="green--text">done_outlined</v-icon>
              </div>
              <div v-else>
                <v-icon class="red--text">highlight_off_outlined</v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table id="exceltable" style="display:none;">
        <thead>
          <tr>
            <th class="text-left" @click.prevent="sortBy('srno')">Sr No. <v-icon>mdi-swap-vertical</v-icon></th>
            <th class="text-left" @click.prevent="sortBy('organization')">Organization <v-icon>mdi-swap-vertical</v-icon></th>
            <th class="text-left" @click.prevent="sortBy('grno')">Grno <v-icon>mdi-swap-vertical</v-icon></th>
            <th class="text-left" @click.prevent="sortBy('name')">Name <v-icon>mdi-swap-vertical</v-icon></th>
            <th class="text-left" @click.prevent="sortBy('program')">Program <v-icon>mdi-swap-vertical</v-icon></th>
            <th class="text-left" v-for="(role, index) in placement_type_list1" :key="index">
              {{ placement_type_list1[index].name }}<v-icon>mdi-swap-vertical</v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in studentdata" :key="index">
            <td class="text-left">{{ index + 1 }}</td>
            <td  class="text-left">{{ item.org }}</td>
            <td class="text-left">{{ item.grno }}</td>
            <td class="text-left">{{ item.name }}</td>
            <td class="text-left">{{ item.program }}</td>
            <td class="text-left" v-for="(item1, index) in item.place" :key="index">
              <div v-if="item1.isregister">
                YES
              </div>
              <div v-else>
                NO
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>

import { table2excel } from "../jquery.table2excel";
import axios from "axios";
import readXlsxFile from "read-excel-file";

export default {
  data() {
    return {
      year_list: [],
      year: '',
      snackbar_msg: "",
      color: "",
      ayid: "",
      aacademic_list: [],
      snackbar: false,
      placement_type_list: [],
      placement_type_list1: [],
      ay_list: [],
      institute_list: [],
      sortKey: '',
      sortDesc: false,
      program_list: [],
      ay: "",
      institute: null,
      programtype: "",
      programtype_list: [],
      program: "",
      offer_type: [],
      studentdata: [],
      loading: false,
      excelerrordialog: false,
      excelfile: null,
      exceldialog: false,
      url: "",
      placement_type_id: null,
      emptylist: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    sortBy(key) {
    this.sortKey = key;
    this.sortDesc = !this.sortDesc; // Toggle sorting direction
    this.studentdata.sort((a, b) => {
      const order = this.sortDesc ? -1 : 1;
      if (a[key] < b[key]) return -1 * order;
      if (a[key] > b[key]) return 1 * order;
      return 0;
    });
  },
    exportexcel() {

      $("#exceltable").table2excel({

        name: "Worksheet Name",
        filename: "TPO_REGISTRATION_REPORT", //do not include extension
        fileext: ".xls" // file extension
      });

    },
    getProgramType() {
      const data = {
        instituteid: this.institute,
      };
      axios
        .post("TPO/getProgramTypeByOrganization", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.programtype_list = res.data.programtype_list;
            if (this.programtype_list.length > 1) {
              this.programtype = "All";
              this.programtype_list.push({ name: 'All', text: '' });
            } else if (this.programtype_list.length > 0) {
              this.programtype = this.programtype_list[0].id;
            }
            this.getProgram();
          } else {
            this.showSnackbar("green", res.data.msg1);
          }
        }).catch((error) => {
          window.console.log(error);
        }).finally(() => { });
    },
    getProgram() {
      const data = {
        instituteid: this.institute,
        programtypeid: this.programtype,
      };
      axios
        .post("TPO/getProgramsByOrganizationAndProgranType", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.program_list = res.data.program_list;
            if (this.program_list.length > 1) {
              this.program = "All";
              this.program_list.push({ name: 'All', text: '' });
            } else if (this.program_list.length > 0) {
              this.program = this.program_list[0].id;
            }
            this.year_list = res.data.year_list;
            if (this.year_list.length > 1) {
            this.year = "All";
            this.year_list.push({ name: 'All', text: '' });
          } else if (this.year_list.length > 0) {
            this.year = this.year_list[0].id;
          }
          } else {
            this.showSnackbar("green", res.data.msg1);
          }
        }).catch((error) => {
          window.console.log(error);
        }).finally(() => { });
    },
    init() {
      axios.post("/TPO/get_tpo_registration_data").then((res) => {
        if (res.data.msg == "200") {
          this.ay_list = res.data.ay_list;
          this.url = res.data.url;
          this.institute_list = res.data.institute_list;
          this.programtype_list = res.data.programtype_list;
          this.program_list = res.data.program_list;
          this.placement_type_list = res.data.placement_type_list;
          this.aacademic_list = res.data.aacademic_list;
          this.year_list = res.data.year_list;
          this.ay  = res.data.iscurrentay;
          this.program_list.push({
            name: "All",
            text: "",
          });
          this.program = "All";
         

          if (this.programtype_list.length > 1) {
            this.programtype = "All";
            this.programtype_list.push({ name: 'All', text: '' });
          } else if (this.programtype_list.length > 0) {
            this.programtype = this.programtype_list[0].id;
          }
          if (this.institute_list.length > 1) {
            this.institute = "All";
            this.institute_list.push({ name: 'All', text: '' });
          } else if (this.institute_list.length > 0) {
            this.institute = this.institute_list[0].id;
          }
          if (this.year_list.length > 1) {
            this.year = "All";
            this.year_list.push({ name: 'All', text: '' });
          } else if (this.year_list.length > 0) {
            this.year = this.year_list[0].id;
          }

          //console.log(this.institute_list);
          this.institute = "All";
          // this.fetchReport();
          console.log(" fetching data!");
        } else {
          console.log("error fetching data!");
        }
      });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    uploadexcel() {
      // alert(this.ayid);
      if (this.excelfile == null) {
        this.showSnackbar("red", "Please select file");
      } else if (this.placement_type_id == null) {
        this.showSnackbar("red", "Please select offertype");
      } else if (this.ayid == "") {
        this.showSnackbar("red", "Please select academic year");
      } else {
        var flag = 0;
        var j = 0;
        var studentlist = [];
        readXlsxFile(this.excelfile).then((rows) => {
          //console.log(rows);
          var i;
          for (i = 1; i < rows.length; i++) {
            studentlist[i - 1] = rows[i][0];
          }

          var params = {
            studentlist: studentlist,
            offertype: this.placement_type_id,
            ay: this.ayid,
          };
          axios.post("/TPO/registerstudent", params).then((res) => {
            if (res.data.msg == "200") {
              this.fetchReport();
              this.showSnackbar("green", "Success..");
            } else {
              this.showSnackbar("red", res.data.msg);
            }
          });
        });

        this.exceldialog = false;
      }
    },
    clear() {
      this.excelfile = null;
      this.exceldialog = false;
    },
    fetchReport() {
      //  alert("this.offer_type :"+this.offer_type);
      this.studentdata = [];
      const data = {
        offer_type: this.offer_type,
        ay: this.ay,
        institute: this.institute,
        programtype: this.programtype,
        program: this.program,
        year:this.year,
      };
      //console.log("in data " + data);

      if (data.ay == null) {
        this.showSnackbar("#b71c1c", "Please select Academic Year!"); // show snackbar
      } else if (data.program == null) {
        this.showSnackbar("#b71c1c", "Please select Progam Name!"); // show snackbar
      } else {

        //console.log(this.placement_type_list.length);
        var x = 0;
        for (var i in this.placement_type_list) {
          if (this.placement_type_list[i].value == true) {
            data.offer_type[x] = this.placement_type_list[i].id;
            x++;
          }
        }
        // console.log("sixe");
        //console.log(data.offer_type.length);
        if (data.offer_type.length == 0) {
          this.showSnackbar("#b71c1c", "Please select Offertype!"); // show snackbar
        } else {
          this.loading = true;

          axios
            .post("/TPO/get_tpo_registration_studentlist", data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.studentdata = res.data.studentdata;
                this.emptylist = res.data.isempty;
                this.placement_type_list1 = res.data.placement_type_list1
                this.loading = false;
                comsole.log("----------------------------------------");
                //console.log(res.data.studentdata);
                if (this.emptylist == 'empty') {
                  this.showSnackbar("#b71c1c", "No Students Found");
                }
              } else {
                this.showSnackbar("#b71c1c", res.data.msg);
              }
            });
        }
      }


    },
  },
};
</script>
<style>
.tc-title {
        background-image: linear-gradient(-90deg, skyblue, #057996);
        color: #fff;
        border-radius: 3px;
}
</style>